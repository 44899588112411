import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"
import Introduction from "../components/P"
import Heading from "../components/Heading"
import { graphql } from "gatsby"
import { get } from "lodash"

const IndexPage = ({ data }) => {
    console.log("data", data)
    const { heading, paragraphs: homeParagraphs = [] } = get(
        data,
        "allHomeJson.edges[0].node",
        {}
    )
    const { copy: headingCopy } = heading

    const { section: sectionTitle = "", clients: clientName = [] } = get(
        data,
        "allSelectWorkJson.edges[0].node",
        {}
    )

    console.log("homeParagraphs", homeParagraphs)

    return (
        <Layout>
            <SEO
                title="Johnnie Aiello | Product Designer & Founder of Descriptive"
                description="Johnnie Aiello is a product designer and the Founder of Descriptive."
            />
            <Heading headingText={headingCopy.header} />
            <Introduction paragraphs={homeParagraphs} />
        </Layout>
    )
}
export default IndexPage

export const query = graphql`
    query {
        allHomeJson {
            edges {
                node {
                    heading {
                        copy {
                            header
                        }
                    }
                    paragraphs {
                        id
                        paragraph
                    }
                }
            }
        }
    }
`
