import React from "react"
import styled from "@emotion/styled"
import { colors } from "../styles/colors"
import { fontFamily } from "../styles/fonts/fontFamily"
import AppWrapper from "../styles/AppWrapper"
import ReactHtmlParser from "react-html-parser"
import { device } from "../styles/device"

export const P = styled.p`
    font-size: 25px;
    color: ${colors.black};
    line-height: 1.3;
    z-index: 9;
    font-family: ${fontFamily.manrope};
    padding-bottom: 45px;

    &:last-of-type {
        padding-bottom: 0;
    }

    a {
        text-decoration: none;
        color: ${colors.purple};
    }

    @media ${device.tablet} {
        font-size: 60px;
        line-height: 1.2;
    }
`

const Section = styled.section``

const Introduction = ({ paragraphs = [] }) => {
    return (
        <>
            <Section>
                <AppWrapper>
                    {paragraphs.map(({ paragraph, id }) => (
                        <P key={id}>{ReactHtmlParser(paragraph)}</P>
                    ))}
                </AppWrapper>
            </Section>
        </>
    )
}
export default Introduction
